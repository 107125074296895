<template>
  <div>
    <van-nav-bar title="我的" />

    <van-button @click="isShow = !isShow">{{
      isShow ? "隐藏" : "显示"
    }}</van-button>

    <transition name="moveCartoon">
      <div v-if="isShow" style="background-color: red">
        <span>淡入淡出动画</span>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "User",

  data() {
    return {
      isShow: false,
    };
  },

  beforeCreate() {
    console.log("创建之前");
  },
  created() {
    console.log("创建后");
  },
  beforeMount() {
    console.log("挂载前");
  },
  mounted() {
    console.log("挂载后");
  },
  beforeUpdate() {
    console.log("更新前状态,重新渲染之前触发");
    // 只有view上面的数据变化才会触发beforeUpdate和updated
  },
  updated() {
    console.log("数据已经更改完成，dom也重新render完成");
  },
  beforeUnmount() {
    console.log("销毁前执行");
  },
  computed() {
    console.log("computed");
  },
  components: {},

  methods: {},

  watch: {
    //查看监听
    $route(newval, oldval) {
      console.log("newval", newval);
      console.log("oldval", oldval);
      if (newval.path == "/user") {
        console.log("watch监听值变化");
      }
    },
    // deep:true
  },
};
</script>

<style scoped>
table {
  width: 70%;
  height: 300px;
}
th {
  height: 50px;
}

tr {
  width: auto;
  height: 50px;
}

td {
  text-align: center;
}

h3 {
  font-size: 20px;
}

.moveCartoon-enter-active,
.moveCartoon-leave-active {
  transition: opacity 1s linear 0s;
}
.moveCartoon-enter,
.moveCartoon-leave-to {
  opacity: 0;
}
</style>